import React from "react";
import { graphql } from "gatsby";

import { defineCustomElements as deckDeckGoHighlightElement } from '@deckdeckgo/highlight-code/dist/loader';

import Layout from "../components/layout";
import SEO from "../components/seo";

deckDeckGoHighlightElement();

export default function Template({ data, location }) {
  const { markdownRemark } = data;
  const { frontmatter, html } = markdownRemark;
  return (
    <Layout>
      <SEO 
        title={frontmatter.title}
        description={frontmatter.title}
        isBlogPost={true}
        datePublished={frontmatter.date}
        url={location.pathname}
        keywords={
          [
            `scriptday blog`,
            `Ruby on Rails blog`,
            `Ruby on Rails`,
            `Ruby on Rails Development Company`,
            `Ruby on Rails Consulting Company`,
            `Ruby blog`,
            `Ruby`,
            `Ruby Consulting Company`,
            `Ruby Development Company`,
            `ReactJS Consulting Company`,
            `ReactJS Development Company`,
          ]
        }
      />
      <div className="mt-10 px-5 md:px-10 lg:px-40 blog-markdown-body">
        <div className="text-center divide-gray-200 divide-y">
          <div>
            <h3 className="text-2xl mb-2">{frontmatter.title}</h3>
            <p className="text-gray-500">{frontmatter.date}</p>
          </div>
          <div
            className="text-left pt-6"
            dangerouslySetInnerHTML={{ __html: html }}
          />
          <div className=" mt-10 pt-10">
            <div className="text-gray-500 italic">
              Share feedback with us at:
              <p className="text-site-blue-dark">
                info@scriptday.com
              </p>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query($slug: String!) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        slug
        title
      }
    }
  }
`
